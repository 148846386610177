<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    :persistent="!options.cancelOutside"
    @keydown.enter="onConfirm"
  >
    <v-card>
      <slot v-if="title" name="title" :title="title" :options="options" :dialog="this">
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title style="white-space: pre-wrap">{{ title }}</v-toolbar-title>
        </v-toolbar>
      </slot>
      <slot v-if="message" name="message" :message="message" :options="options" :dialog="this">
        <v-card-text class="pa-4" style="white-space: pre-wrap">{{ message }}</v-card-text>
      </slot>
      <slot
        name="actions"
        :onConfirm="onConfirm"
        :onCancel="onCancel"
        :close="close"
        :options="options"
        :dialog="this"
      >
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="onCancel" v-if="options.cancelBtn">
            {{ options.cancelBtn }}
          </v-btn>
          <v-btn :color="options.color" text @click="onConfirm" v-if="options.confirmBtn">
            {{ options.confirmBtn }}
          </v-btn>
        </v-card-actions>
      </slot>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    promise: null,
    message: null,
    title: null,
    opts: {},
  }),
  computed: {
    defaultOptions() {
      return Object.freeze({
        color: 'primary',
        width: 400,
        zIndex: 200,
        confirmBtn: 'Yes',
        cancelBtn: 'Cancel',
        cancelOutside: true,
        onConfirm: () => Promise.resolve(true),
        onCancel: () => Promise.resolve(false),
      });
    },
    options() {
      return Object.assign({}, this.defaultOptions, this.opts);
    },
  },
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.opts = options;
      if (!this.promise) {
        this.promise = new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      }
      return this.promise;
    },
    close(result) {
      this.resolve?.(result);
      this.dialog = false;
      this.resolve = this.reject = this.promise = null;
    },
    async onConfirm() {
      try {
        this.close(await this.options.onConfirm());
      } catch (e) {
        this.reject?.(e);
      } finally {
        this.resolve = this.reject = this.promise = null;
      }
    },
    async onCancel() {
      try {
        this.close(await this.options.onCancel());
      } catch (e) {
        this.reject?.(e);
      } finally {
        this.resolve = this.reject = this.promise = null;
      }
    },
  },
};
</script>
